// src/components/Preloader.js
import React from 'react';
import Lottie from "lottie-react";
import DecojentPreloader from "../assets/DecojentPreloader.json"


const Preloader = () => {
  return (
    <div id='pre-loader'>
        <Lottie 
          animationData={DecojentPreloader} 
          loop={true}
          className="preloader-svg"
          />
    </div>
  );
};

export default Preloader;
